import React from 'react';
import { PageProps, graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import locales from 'constants/locales';

interface Props extends PageProps {
    pageContext: { locale: keyof typeof locales };
    data: {
        markdownRemark: {
            frontmatter: {
                title: string;
                subtitle: string;
                content: string;
            };
        };
    };
}

const NotFoundPage: React.FC<Props> = ({ data, location, pageContext: { locale } }) => {
    const {
        markdownRemark: { frontmatter },
    } = data;

    const sectionTitle = {
        title: frontmatter.title,
        subtitle: frontmatter.subtitle,
    };
    const { content } = frontmatter;

    return (
        <Layout locale={locale}>
            <SEO
                path={location ? location.pathname : ''}
                locale={locale}
                title={sectionTitle.title}
                description={sectionTitle.subtitle}
            />
            <Container>
                <TitleSection center title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
                <p className="mt-4 text-center w-full">{content}</p>
            </Container>
        </Layout>
    );
};

export const query = graphql`
    query Query404($locale: String!) {
        markdownRemark(frontmatter: { category: { eq: "404" }, locale: { eq: $locale } }) {
            frontmatter {
                title
                subtitle
                content
            }
        }
    }
`;

export default NotFoundPage;
